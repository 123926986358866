import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#19192F',
      paper: '#1f294a',
    },
  },
  shape: {
    borderRadius: 12,
  },
  // components: {
  //   MuiButton: {
  //     styleOverrides: {
  //       root: {
  //         textTransform: 'none',
  //       },
  //     },
  //   },
  // },
  typography: {
    fontFamily: ['Inter-SemiBold !important'].join(','),
    button: {
      textTransform: 'none',
    },
  },
});

export default theme;
