import { Backdrop, CircularProgress, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import theme from './theme';
import { WalletProvider, getChainOptions } from '@terra-money/wallet-provider';
import { Web3ReactProvider } from '@web3-react/core';
// import { Web3Provider } from '@ethersproject/providers';
import Web3 from 'web3';

function getLibrary(provider: any) {
  return new Web3(provider);
}

const loadingMarkup = (
  <Backdrop
    sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
    open={true}
  >
    <CircularProgress color='primary' />
  </Backdrop>
);

getChainOptions().then(chainOptions => {
  ReactDOM.render(
    <WalletProvider {...chainOptions}>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Suspense fallback={loadingMarkup}>
            <Web3ReactProvider getLibrary={getLibrary}>
              <App />
            </Web3ReactProvider>
          </Suspense>
        </ThemeProvider>
      </React.StrictMode>
    </WalletProvider>,
    document.getElementById('root')
  );
});
